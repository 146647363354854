export enum GoalStatusEnum {
  /* eslint-disable no-unused-vars */
  INITIAL = 'INITIAL',
  STARTED = 'STARTED',
  PAUSED = 'PAUSED',
  COMPLETED = 'COMPLETED',
  /* eslint-enable no-unused-vars */
}

export enum GoalRepeatFrequencyEnum {
  /* eslint-disable no-unused-vars */
  DAILY = 'DAILY',
  SUNDAY = 'SUNDAY',
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  /* eslint-enable no-unused-vars */
}

export interface IGoalSetterTask {
  id?: string,
  description: string,
  completed: boolean
}

export interface IGoalSetter {
  id?: string,
  title: string,
  startDate: Date,
  status: GoalStatusEnum,
  completedPercentage: number,
  reminderInterval?: number,
  repeatFrequency?: GoalRepeatFrequencyEnum[],
  pinned: boolean,
  tasks: IGoalSetterTask[],
  visibleFields?: Array<string>,
  uen?: string,
}

export interface IGoalGroup {
  upcomingGoals?: Array<IGoalSetter>,
  startedGoals?: Array<IGoalSetter>,
  pausedGoals?: Array<IGoalSetter>,
  completedGoals?: Array<IGoalSetter>,
}

export interface GoalState {
  goals: IGoalGroup,
  goalData?: IGoalSetter,
}
