












import Vue from 'vue';
import { IGoalGroup, IGoalSetter } from '@/scripts/store/modules/tools/goals/types';
import goalForm from '@/views/tools/goals/components/goal-form.vue';

export default Vue.extend({
  name: 'edit-goal',
  components: {
    goalForm,
  },
  props: {
    goal: {
      type: Promise,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      loadedGoal: null as IGoalSetter | null,
    };
  },
  computed: {
    storeGoals(): IGoalGroup {
      return this.$store.getters['goals/goals'];
    },
    hasGoals(): Boolean {
      return !!this.storeGoals.upcomingGoals?.length || !!this.storeGoals.startedGoals?.length || !!this.storeGoals.pausedGoals?.length || !!this.storeGoals.completedGoals?.length;
    },
  },
  mounted() {
    this.goal.then(res => {
      this.loadedGoal = res as IGoalSetter;
    });
  },
  methods: {
    goToGoalList() {
      this.$router.replace({ name: 'goals-list' });
    },
  },
});
