var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loadedGoal
    ? _c(
        "v-layout",
        {
          staticClass: "edit-goal",
          attrs: { row: "", wrap: "", "pt-3": "", "gap-xs-3": "" }
        },
        [
          _c("v-flex", { attrs: { xs12: "" } }, [
            _c("p", { staticClass: "starling-text" }, [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "app.tools.goal_setter." +
                      (_vm.hasGoals ? "title_other" : "title_first")
                  )
                )
              )
            ])
          ]),
          _c(
            "v-flex",
            { attrs: { xs12: "", lg9: "", "text-left": "" } },
            [
              _c("goal-form", {
                attrs: { goal: _vm.loadedGoal, cancellable: "" },
                on: { update: _vm.goToGoalList, cancel: _vm.goToGoalList }
              })
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }